import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { bindRouter } from '@wix/tpa-router/plugins';

import {
  initializeRouter,
  registerApplicationRoutes,
  reloadOnLogin,
} from 'controller/router';
import { resolvables } from 'controller/resolvables';
import { initializeStore, persistStore } from 'controller/store';
import { migrateStyles } from 'controller/tpa-styles-migration';
import { bindViewModel, initializeViewModel } from 'controller/view-model';
import { bindIntegrations } from 'controller/integrations';

import { resolve } from './resolve';
import { configureRouter } from './config';

export const controller: CreateControllerFn = function (params) {
  const { flowAPI } = params;
  const { wixCodeApi } = params.controllerConfig;
  const router = initializeRouter(params);

  flowAPI.bi?.updateDefaults({ userEntry: 'site' });

  return {
    async pageReady() {
      const store = initializeStore(params);
      const vm = await initializeViewModel(router, store, params);

      configureRouter(router);
      reloadOnLogin(router, params);
      registerApplicationRoutes(router, vm, store, params);

      migrateStyles(params);
      bindViewModel(vm, store, params);
      bindRouter(router, params.flowAPI);

      await resolvables(params, resolve(router, params));

      bindIntegrations(vm);
      persistStore(store, params);
    },
    async onBeforeUnLoad() {
      router.dispose();
    },
  };
};
